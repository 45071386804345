import React, { FunctionComponent } from 'react';

import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { rhythm } from '../utils/typography';
import { Article } from '../article.definition';
import TimeToRead from './TimeToRead';
import TagLinkList from './TagLinkList';
import { GatsbyImage } from 'gatsby-plugin-image';

type ArticleListProps = {
  nodes: Article[];
};

const OrderedArticlesList = styled.ol`
  display: grid;
  list-style: none;
  justify-items: center;
  grid-gap: 1.77rem;
  margin: 20px 0;

  @media (min-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1680px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ArticleBannerContainer = styled.div`
  @media (min-width: 680px) {
    height: 160px;
  }
`;

const ArticleTitle = styled.h2`
  margin-bottom: ${rhythm(1 / 4)};
  margin-top: 0;
  font-weight: bold;
  text-align: start;
  font-size: 1.3em;
`;

const ArticleContainer = styled.li`
  display: flex;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  justify-items: center;
  flex-direction: column;

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

    ${ArticleTitle} {
      a {
        color: #e90000;
      }
    }
  }
`;

const ArticleList: FunctionComponent<ArticleListProps> = ({ nodes }) => (
  <OrderedArticlesList>
    {nodes.map(({ node }) => {
      const { title, date, emojiRatio, timeEmoji } = node.frontmatter;
      return (
        <ArticleContainer key={node.fields.slug} style={{textAlign: 'justify'}} role="article">
          {
            node.frontmatter.ogimage && 
            <ArticleBannerContainer>
              <GatsbyImage image={node.frontmatter.ogimage.childImageSharp.gatsbyImageData} alt={node.frontmatter.title} style={{ maxHeight: 160 }}/>
            </ArticleBannerContainer>
          }
          <div style={{padding: 15}}>
            <ArticleTitle>
              <Link to={node.fields.slug} title={title}>
                {title}
              </Link>
            </ArticleTitle>
            {node.fields.tags && <><TagLinkList tags={node.fields.tags} /><br/></>}
            <small>
              {date} •&nbsp;
              <TimeToRead timeToRead={node.timeToRead} emoji={timeEmoji} ratio={emojiRatio} />
            </small>
            <p
              style={{ marginTop: rhythm(1 / 2), marginBottom: rhythm(1 / 2) }}
              dangerouslySetInnerHTML={{ __html: node.excerpt }}
            />
          </div>
        </ArticleContainer>
      );
    })}
  </OrderedArticlesList>
);

export default ArticleList;
