import React, { FunctionComponent } from 'react';

import { Link } from 'gatsby';

const TagLink: FunctionComponent<{ tag: string }> = ({ tag }) => (
  <Link
    to={`/tags/${tag}`}
    style={{
      display: 'inline-block',
      marginRight: 8,
      borderRadius: 4,
      padding: 4,
      color: '#181E6F',
      backgroundColor: '#E8EEFF',
      fontSize: '0.8em',
    }}
  >{`#${tag}`}</Link>
);

const TagLinkList: FunctionComponent<{ tags: string[] }> = ({ tags }) => (
  <>
    {tags.map((tag) => (
      <TagLink tag={tag} key={tag} />
    ))}
  </>
);

export default TagLinkList;
