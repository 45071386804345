import React, { FunctionComponent } from 'react';

export type TimeToReadProps = {
  timeToRead?: number;
  emoji?: string;
  ratio?: number;
};

const TimeToRead: FunctionComponent<TimeToReadProps> = (props) => {
  const { timeToRead = 1, emoji = '🍺', ratio = 5 } = props;
  const numberOfEmojis = Math.round(timeToRead / ratio) || 1;
  const emojiLine = Array(numberOfEmojis)
    .fill(emoji)
    .join('');

  return (
    <>
      <span className="emoji" title="Estimoji du temps de lecture">{emojiLine}</span> {timeToRead} min. de lecture
    </>
  );
};

export default TimeToRead;
